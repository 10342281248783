$pt-intent-primary: #27aa6d;
$pt-outline-color: rgba(#27aa6d, 0.6);
$pt-link-color: #27aa6d !default;
$pt-dark-link-color: rgb(11, 104, 65) 0 !default;

$pt-intent-text-colors: (
  'primary': #27aa6d,
  'success': #27aa6d,
  'warning': #bf7326,
  'danger': #c23030,
) !default;

$pt-dark-intent-text-colors: (
  'primary': #27aa6d,
  'success': #27aa6d,
  'warning': #ffb366,
  'danger': #ff7373,
) !default;

$button-intents: (
  'primary': (
    #27aa6d,
    #0a6640,
    #27aa6d,
  ),
  'success': (
    #27aa6d,
    #0a6640,
    #27aa6d,
  ),
  'warning': (
    #d9822b,
    #bf7326,
    #a66321,
  ),
  'danger': (
    #db3737,
    #c23030,
    #a82a2a,
  ),
) !default;

@import '~@blueprintjs/core/src/blueprint.scss';
