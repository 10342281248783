body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.lightbox-btn {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  background: none;
  border: 1px solid transparent;
  fill: #fff;
  opacity: 0.8;
  outline: none;
  margin: 2px;
  padding: 0;
  -webkit-tap-highlight-color: #ffffff00;
}

.lightbox-btn:hover {
  opacity: 1;
  -webkit-transition: opacity 0.2s ease;
  -o-transition: opacity 0.2s ease;
  transition: opacity 0.2s ease;
}

.lightbox-btn--ripple circle {
  fill: none;
}

.lightbox-btn--ripple:hover circle {
  -webkit-transform: scale(3);
  -ms-transform: scale(3);
  transform: scale(3);
  -webkit-transform-origin: 33% 33%;
  -ms-transform-origin: 33% 33%;
  transform-origin: 33% 33%;
  fill: #64646480;
  -webkit-transition: -webkit-transform 0.2s ease;
  transition: -webkit-transform 0.2s ease;
  -o-transition: transform 0.2s ease;
  transition: transform 0.2s ease;
  transition: transform 0.2s ease, -webkit-transform 0.2s ease;
}

.lightbox-content-center {
  width: 100%;
  height: 100%;
  position: absolute;
}

.lightbox-modifiers-box {
  background-color: rgba(0, 0, 0, 0.2);
  text-align: center;
  font-size: 1.32px;
  position: absolute;
  width: 100%;
  z-index: 1;
}

@media (min-height: 600px) {
  .lightbox-modifiers-box {
    background-color: transparent;
  }
}

@media (min-width: 768px) {
  .lightbox-image-container {
    margin: 0 5em;
  }
}

.lightbox-image-container {
  overflow: hidden;
  height: calc(100% - 102px);
  margin-top: 40px;
}

.lightbox-image {
  height: 100%;
  background-repeat: no-repeat;
}

.lightbox-image.moving {
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
}

.lightbox-loader {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
  fill: #fff;
  -webkit-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}

@-webkit-keyframes rotating {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(-1turn);
    transform: rotate(-1turn);
  }
}

@keyframes rotating {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(-1turn);
    transform: rotate(-1turn);
  }
}

html.lightbox-open {
  overflow: hidden;
  position: fixed;
  width: 100%;
}

.lightbox-backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
  z-index: 100;
}

.lightbox-btn-close,
.lightbox-btn-left,
.lightbox-btn-right,
.lightbox-modifiers-box,
.lightbox-title-content {
  -webkit-transition: opacity 0.2s ease;
  -o-transition: opacity 0.2s ease;
  transition: opacity 0.2s ease;
}

@media (max-width: 767px) {
  .hide-controls .lightbox-btn-left,
  .hide-controls .lightbox-btn-right {
    opacity: 0;
    pointer-events: none;
  }
}

@media (max-height: 599px) {
  .hide-controls .lightbox-btn-close,
  .hide-controls .lightbox-modifiers-box,
  .hide-controls .lightbox-title-content {
    opacity: 0;
    pointer-events: none;
  }
}

.lightbox-btn-close {
  position: fixed;
  left: 0;
  z-index: 2;
}

.lightbox-btn-right {
  right: 0;
}

.lightbox-btn-left,
.lightbox-btn-right {
  position: absolute;
  top: 50%;
  margin-top: -1.5em;
}

.lightbox-btn-left {
  left: 0;
}

@media (min-width: 768px) {
  .lightbox-btn-right {
    padding-right: 10px;
  }

  .lightbox-btn-left {
    padding-left: 10px;
  }
}

.lightbox-title-content {
  background-color: rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12);
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  position: absolute;
  width: 100%;
  z-index: 1;
  bottom: 0;
  text-align: center;
  padding: 10px;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  line-height: 1.42857143;
}

.lightbox-title {
  color: #fff;
  font-weight: 600;
  font-size: 16px;
}

.lightbox-description {
  color: #c5c5c5;
  font-size: 14px;
}

.lightbox-transition-image-appear {
  opacity: 0.1;
}

.lightbox-transition-image-appear.lightbox-transition-image-appear-active {
  opacity: 1;
  -webkit-transition: opacity 0.3s ease-in;
  -o-transition: opacity 0.3s ease-in;
  transition: opacity 0.3s ease-in;
}

.lightbox-transition-image-enter-right {
  -webkit-transform: translate(100%);
  -ms-transform: translate(100%);
  transform: translate(100%);
}

.lightbox-transition-image-enter-right.lightbox-transition-image-enter-right-active {
  -webkit-transform: translate(0);
  -ms-transform: translate(0);
  transform: translate(0);
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  transition: -webkit-transform 0.3s ease-in-out;
  -o-transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}

.lightbox-transition-image-leave-right {
  -webkit-transform: translate(0);
  -ms-transform: translate(0);
  transform: translate(0);
}

.lightbox-transition-image-leave-right.lightbox-transition-image-leave-right-active {
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  transition: -webkit-transform 0.3s ease-in-out;
  -o-transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}

.lightbox-transition-image-enter-left,
.lightbox-transition-image-leave-right.lightbox-transition-image-leave-right-active {
  -webkit-transform: translate(-100%);
  -ms-transform: translate(-100%);
  transform: translate(-100%);
}

.lightbox-transition-image-enter-left.lightbox-transition-image-enter-left-active {
  -webkit-transform: translate(0);
  -ms-transform: translate(0);
  transform: translate(0);
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  transition: -webkit-transform 0.3s ease-in-out;
  -o-transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}

.lightbox-transition-image-leave-left {
  -webkit-transform: translate(0);
  -ms-transform: translate(0);
  transform: translate(0);
}

.lightbox-transition-image-leave-left.lightbox-transition-image-leave-left-active {
  -webkit-transform: translate(100%);
  -ms-transform: translate(100%);
  transform: translate(100%);
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  transition: -webkit-transform 0.3s ease-in-out;
  -o-transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}

.lightbox-img-thumbnail {
  -webkit-box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 2px;
  margin: 10px;
  cursor: pointer;
}

@media (min-width: 768px) {
  .lightbox-img-thumbnail:hover {
    -webkit-box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14),
      0 3px 14px 2px rgba(0, 0, 0, 0.12);
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14),
      0 3px 14px 2px rgba(0, 0, 0, 0.12);
    -webkit-transition: all 0.45s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    -o-transition: all 0.45s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    transition: all 0.45s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  }
}
