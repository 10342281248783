.carousel {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.carousel > :nth-child(1) { 
  order: 2; 
}

.carousel-indicators {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0;
  position: unset;
  margin-top: 50px;
}

.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  background-color: white;
  height: 100%;
}

.carousel-indicators .active {
  background-color: #11B048 !important;
}

.carousel-indicators [data-bs-target] {
  background-color: #C4C4C4;
  width: 14px;
  height: 14px;
  border-radius: 50%;
}

.carousel-inner {
  height: 100%;
}
